import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'; 
import Navbar from './Navbar';
import Home from './Home';
import NotFound from './NotFound';



function App() {
  const [data, setData] = useState('');

  useEffect(() => {
    (async function () {
      const { text } = await( await fetch(`/api/message`)).json();
      setData(text);
    })();
  });

  return <div className='App'>
    <Router>
      <Navbar/>
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="*" element={<NotFound />}/>
        </Routes>
      </div>
    </Router> 
    {data}
  </div>;
}


export default App;
