import React from 'react';
import IntroBlog from './blogs/IntroBlog';

const Home = () => {
    //const {data: blogs, isPending, error} = useFetch('http://localhost:8000/blogs');
    return ( 
        <div className="home">
            <IntroBlog />
        </div>
     );
}

export default Home;