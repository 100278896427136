import React from 'react';

const list = [
    {
      id: 'a',
      title: 'Build First Static Website',
      link: 'https://docs.microsoft.com/en-us/azure/static-web-apps/getting-started?tabs=react'
    },
    {
        id: 'b',
        title: 'Add API to Static Website',
        link: 'https://docs.microsoft.com/en-us/azure/static-web-apps/add-api?tabs=react'
      },
      {
        id: 'c',
        title: 'Setup CNAME',
        link: 'https://docs.microsoft.com/en-us/azure/static-web-apps/custom-domain-external'
      },      
      {
        id: 'd',
        title: 'Build a Blog app',
        link: 'https://javascript.plainenglish.io/build-a-blog-app-with-react-intro-and-set-up-part-1-ddf5c674d25b'
      },            
  ];
  
  const HelpList = () => (
    <ul>
      {list.map(item => (
        <li key={item.id}>
          <a target="_blank" rel="noopener noreferrer" href={item.link}>{item.title}</a>
        </li>
      ))}
    </ul>
  );

  const futurework = [
    {
      id: 'a',
      title: 'Setup Blog APIs',
    },
    {
        id: 'b',
        title: 'Add Analytics',
      },
      {
        id: 'c',
        title: 'Details for setting up CNAME',
      },      
      {
        id: 'd',
        title: 'How to "Style" the website',
      },            
      {
        id: 'e',
        title: 'How to setup keywords',
      },                  
      {
        id: 'f',
        title: 'Monitor Azure Spend',
      },                        
      {
        id: 'g',
        title: 'Gain Subscribers',
      },                              
      {
        id: 'h',
        title: 'Email Subscribers',
      },                                
      {
        id: 'i',
        title: 'Archive Blog Posts',
      },            
      {
        id: 'j',
        title: 'Make Blog Posts Searchable',
      },                  
  ];

  const FutureList = () => (
    <ul>
      {futurework.map(item => (
        <li key={item.id}>
          {item.title}
        </li>
      ))}
    </ul>
  );


const IntroBlog = () => {
     return ( 
         <div className="blog-details">
             <h2>Introduction</h2>
             <p>Hello, I intend to chronicle the changes I make to this blog as I implement it.  Basically, the blog is how to make a blog.  Today, I used a few resources to figure out how to get the website stood up.
             </p>
             <HelpList/>
             <p>
                In the future, I hope to create more posts that explain how to add various functionality to your website.  A few of my current ideas are below:
             </p>
             <FutureList/>
         </div>
      );
}
 
export default IntroBlog;